<template>
    <v-row>
        <XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
        <v-col cols="12" sm="9" md="9" style="height:100%">
            <v-card height="100%" style="overflow: auto;">
                <v-card-title>{{ xiaoqu ? xiaoqu.name : '' }} 收款记录
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :disabled="!xiaoqu" @click="search" :loading="loading" class="mr-5">
                        查询收款单
                    </v-btn>
                    <v-btn color="primary" :disabled="!xiaoqu" @click="search_feiyong" :loading="loading" class="mr-5">
                        查询收款明细
                    </v-btn>
                    <download-excel :data="skd_items" :fields="excelFields"
                        :name="(xiaoqu ? xiaoqu.name : '') + '收款记录'">
                        <v-btn color="success" :disabled="!skd_items.length" :loading="loading">
                            导出
                        </v-btn>
                    </download-excel>
                </v-card-title>
                <v-card-title class="flex-nowrap">
                    <!-- 开始时间 -->
                    <V-menu v-model="startMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="startDate" label="收款开始时间" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="startDate" @input="startMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <!-- 结束时间 -->
                    <V-menu v-model="endMenu" absolute :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDate" label="收款结束时间" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
                        </template>
                        <v-date-picker v-model="endDate" @input="endMenu = false" locale="zh-CN"></v-date-picker>
                    </V-menu>
                    <!-- 楼栋下拉框 -->
                    <v-select :disabled="xiaoqu ? false : true" :items="loudong_items" label="楼栋" item-text="name"
                        item-value="_id" v-model="loudong_selected" outlined dense hide-details class="mx-5"
                        @change="change_loudong"></v-select>

                    <!-- 单元下拉框 -->
                    <v-select :disabled="loudong_selected != 0 ? false : true" :items="danyuan_items" label="单元"
                        item-text="name" item-value="_id" v-model="danyuan_selected" outlined dense hide-details
                        class="mr-5" @change="change_danyuan"></v-select>

                    <!-- 房屋下拉框 -->
                    <v-select :disabled="danyuan_selected != 0 ? false : true" :items="fangwu_items" label="房屋"
                        item-text="name" item-value="_id" v-model="fangwu_selected" outlined dense hide-details
                        class="mr-5"></v-select>

                    <!-- 状态下拉框 -->
                    <v-select :items="status_items" label="类型" v-model="status_selected" outlined dense hide-details
                        class="mr-5"></v-select>

                    <!-- 收款方式下拉框 -->
                    <v-select :items="pay_way_items" label="收款方式" v-model="pay_way_selected" outlined dense
                        hide-details></v-select>
                </v-card-title>
                <v-card-text>
                    <!-- 表格 -->
                    <v-data-table :headers="headers" :items="skd_items" item-key="_id" :loading="table_loading"
                        :options.sync="options" :server-items-length="total_num" height="60vh">
                        <template v-slot:[`item.fanghao`]="{ item }">
                            {{ item.loudong.name + item.danyuan.name + item.fangwu.name }}
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                            {{ new Date(item.date).toLocaleString() }}
                        </template>
                        <template v-slot:[`item.shoukuandan.date`]="{ item }">
                            {{ $dayjs(item.shoukuandan.date).format('YYYY-MM-DD') }}
                        </template>
                        <template v-slot:[`item.user`]="{ item }">
                            {{ item.user ? item.user.nickname : '无' }}
                        </template>
                        <template v-slot:[`item.total`]="{ item }">
                            {{ Math.round(item.total * 100) / 100 }}
                        </template>
                        <template v-slot:[`item.youhui`]="{ item }">
                            {{ Math.round(item.youhui * 100) / 100 }}
                        </template>
                        <template v-slot:[`item.zongji`]="{ item }">
                            {{ Math.round(item.zongji * 100) / 100 }}
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                            <v-chip v-if="item.status == 0" color="green" dark>已收款</v-chip>
                            <v-chip v-if="item.status == 1" color="red" dark>已撤销</v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn color="primary"
                                @click="shoukuandan_id = item._id; shoukuandan_dialog = true">详情</v-btn>
                        </template>
                        <template v-slot:[`body.append`]="{ headers }">
                            <tr>
                                <td v-for="(header, i) in headers" :key="i">
                                    <div v-if="header.text == '日期'">
                                        汇总
                                    </div>
                                    <div v-if="header.text == '应收'">
                                        {{ pageZongji }}
                                    </div>
                                    <div v-if="header.text == '优惠'">
                                        {{ pageYouhui }}
                                    </div>
                                    <div v-if="header.text == '实收'">
                                        {{ pageTotal }}
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="shoukuandan_dialog" width="900">
                        <shoukuandan v-on:close="shoukuandan_dialog = false" :shoukuandan_id="shoukuandan_id">
                        </shoukuandan>
                    </v-dialog>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import shoukuandanComponent from './components/shoukuandanView.vue'
import JsonExcel from "vue-json-excel";
export default {
    components: {
        XiaoquLists,
        'shoukuandan': shoukuandanComponent,
        'downloadExcel': JsonExcel
    },
    data: () => ({
        xiaoqu: null,
        options: {},
        total_num: 0,
        loading: false,
        table_loading: false,

        // 日期选择框
        startMenu: false,
        endMenu: false,
        startDate: new Date().toLocaleDateString().replace(/\//g, '-'),
        endDate: new Date().toLocaleDateString().replace(/\//g, '-'),

        // 下拉框数组
        loudong_items: [{ _id: 0, name: '全部' }],
        danyuan_items: [{ _id: 0, name: '全部' }],
        fangwu_items: [{ _id: 0, name: '全部' }],
        status_items: [{ value: 0, text: '已收款' }, { value: 1, text: '已撤销' }],
        pay_way_items: [
            { value: '全部', text: '全部' },
            { value: '现金', text: '现金' },
            { value: '刷卡', text: '刷卡' },
            { value: '微信', text: '微信' },
            { value: '支付宝', text: '支付宝' },
            { value: '对公', text: '对公' },
            { value: '平台', text: '平台' },
            { value: '预存', text: '预存' },
            { value: 'wxpay-jsapi', text: '小程序' },
            { value: 'wxpay-app', text: 'APP' },
        ],

        // 下拉框选择项
        loudong_selected: 0,
        danyuan_selected: 0,
        fangwu_selected: 0,
        status_selected: 0,
        pay_way_selected: '全部',
        shoukuandan_dialog: false,
        shoukuandan_id: '',
        // 收款单列表
        skd_items: [],
        pageZongji: 0,
        pageYouhui: 0,
        pageTotal: 0,
        headers: [],
        headers_shoukuandans: [
            { text: "日期", sortable: false, value: "date" },
            { text: "房号", sortable: false, value: "fanghao" },
            { text: "收款单", sortable: false, value: "_id" },
            { text: "收款人", sortable: false, value: "user" },
            { text: "收款方式", sortable: false, value: "pay_way" },
            { text: "应收", sortable: false, value: "zongji" },
            { text: "优惠", sortable: false, value: "youhui" },
            { text: "实收", sortable: false, value: "total" },
            { text: "备注", sortable: false, value: "remark" },
            { text: "状态", sortable: false, value: "status" },
            { text: "查看", sortable: false, value: "actions" },
        ],
        headers_feiyongs: [
            { text: "收款日期", value: "shoukuandan.date" },
            { text: "楼栋", value: "loudong.name" },
            { text: "单元", value: "danyuan.name" },
            { text: "房屋", value: "fangwu.name" },
            { text: "业主", value: "fangwu.yezhu" },
            { text: "电话", value: "fangwu.phone" },
            { text: "名称", value: "mingcheng" },
            { text: "单价", value: "danjia" },
            { text: "起数", value: "start" },
            { text: "止数", value: "end" },
            { text: "周期/倍数", value: "zhouqi_beishu" },
            { text: "应收", value: "zongji" },
            { text: "优惠", value: "youhui" },
            { text: "备注", value: "beizhu" },
        ],
        excelFields: {},
        excelFields_shoukuandan: {
            "日期": {
                field: "date",
                callback: (value) => {
                    return new Date(value).toLocaleString()
                },
            },
            "楼栋": "loudong.name",
            "单元": "danyuan.name",
            "房号": "fangwu.name",
            "收款单": "_id",
            "收款人": "user.nickname",
            "收款方式": "pay_way",
            "应收": "zongji",
            "优惠": "youhui",
            "实收": "total",
            "备注": "remark",
            "状态": {
                field: "status",
                callback: (value) => {
                    return value ? '已撤销' : '已收款'
                },
            },
        },
        excelFields_feiyong: {
            "收款日期": {
                field: "shoukuandan.date",
                callback: (value) => {
                    return new Date(value).toLocaleString()
                },
            },
            "楼栋": "loudong.name",
            "单元": "danyuan.name",
            "房号": "fangwu.name",
            "业主": "fangwu.yezhu",
            "名称": "mingcheng",
            "单价": "danjia",
            "起数": "start",
            "止数": "end",
            "周期/倍数": "zhouqi_beishu",
            "应收": "zongji",
            "优惠": "youhui",
            "备注": "remark",
        }
    }),
    watch: {
        options: {
            handler() {
                if (this.xiaoqu) {
                    this.search()
                }
            },
            deep: true,
        },
    },
    methods: {

        // 子组件事件 选择小区
        select_xiaoqu(xiaoqu) {
            // 清除
            this.loudong_items = [{ _id: 0, name: '全部' }]
            this.loudong_selected = 0
            this.danyuan_items = [{ _id: 0, name: '全部' }]
            this.danyuan_selected = 0
            this.fangwu_items = [{ _id: 0, name: '全部' }]
            this.fangwu_selected = 0

            this.xiaoqu = xiaoqu
            if (xiaoqu) {
                // 获取收款单列表
                this.get_loudong_items()
            }
            this.skd_items = []
            this.pageZongji = 0
            this.pageYouhui = 0
            this.pageTotal = 0
        },
        // 获取楼栋列表
        get_loudong_items() {
            this.$axios.get("/loudong/getlist", { params: { xiaoqu: this.xiaoqu._id } })
                .then((res) => {
                    this.loudong_items = [{ _id: 0, name: '全部' }, ...res.data]
                })
        },
        // 选择楼栋
        change_loudong() {
            this.danyuan_items = [{ _id: 0, name: '全部' }]
            this.danyuan_selected = 0
            if (this.loudong_selected != 0) {
                this.$axios.get('/danyuan/getlist', { params: { loudong: this.loudong_selected } })
                    .then((res) => {
                        this.danyuan_items = [{ _id: 0, name: '全部' }, ...res.data]
                    })
            }
        },
        // 选择单元
        change_danyuan() {
            this.fangwu_items = [{ _id: 0, name: '全部' }]
            this.fangwu_selected = 0
            if (this.danyuan_selected != 0) {
                this.$axios.get('/fangwu/getlist', { params: { danyuan: this.danyuan_selected } })
                    .then((res) => {
                        this.fangwu_items = [{ _id: 0, name: '全部' }, ...res.data]
                    })
            }
        },
        // 查询收款单列表
        search() {
            this.headers = this.headers_shoukuandans
            this.excelFields = this.excelFields_shoukuandan
            this.loading = true
            this.table_loading = true
            var get_data = {
                startDate: this.startDate, endDate: this.endDate, xiaoqu: this.xiaoqu._id, page: this.options.page, itemsPerPage: this.options.itemsPerPage, status: this.status_selected,
            }
            if (this.loudong_selected) {
                get_data.loudong = this.loudong_selected
            }
            if (this.danyuan_selected) {
                get_data.danyuan = this.danyuan_selected
            }
            if (this.fangwu_selected) {
                get_data.fangwu = this.fangwu_selected
            }
            if (this.pay_way_selected != '全部') {
                get_data.pay_way = this.pay_way_selected
            }
            this.$axios.get("/shoukuandan/xiaoqu/lists", { params: get_data })
                .then((res) => {
                    this.pageZongji = 0
                    this.pageYouhui = 0
                    this.pageTotal = 0
                    res.data.doc.forEach(element => {
                        this.pageZongji += element.zongji
                        this.pageYouhui += element.youhui
                        this.pageTotal += element.total

                    });
                    this.pageZongji = Math.round(this.pageZongji * 100) / 100
                    this.pageYouhui = Math.round(this.pageYouhui * 100) / 100
                    this.pageTotal = Math.round(this.pageTotal * 100) / 100
                    this.loading = false
                    this.table_loading = false
                    this.skd_items = res.data.doc
                    this.total_num = res.data.total_num
                })
        },
        // 查询明细
        search_feiyong() {
            this.headers = this.headers_feiyongs
            this.excelFields = this.excelFields_feiyong
            this.loading = true
            this.table_loading = true
            var get_data = {
                startDate: this.startDate, endDate: this.endDate, xiaoqu: this.xiaoqu._id, page: this.options.page, itemsPerPage: this.options.itemsPerPage, status: this.status_selected,
            }
            if (this.loudong_selected) {
                get_data.loudong = this.loudong_selected
            }
            if (this.danyuan_selected) {
                get_data.danyuan = this.danyuan_selected
            }
            if (this.fangwu_selected) {
                get_data.fangwu = this.fangwu_selected
            }
            if (this.pay_way_selected != '全部') {
                get_data.pay_way = this.pay_way_selected
            }
            this.$axios.get("/shoukuandan/xiaoqu/lists/feiyong", { params: get_data })
                .then((res) => {
                    this.pageZongji = 0
                    this.pageYouhui = 0
                    this.pageTotal = 0
                    res.data.doc.forEach(element => {
                        this.pageZongji += element.zongji
                        this.pageYouhui += element.youhui
                        this.pageTotal += element.total

                    });
                    this.pageZongji = Math.round(this.pageZongji * 100) / 100
                    this.pageYouhui = Math.round(this.pageYouhui * 100) / 100
                    this.pageTotal = Math.round(this.pageTotal * 100) / 100
                    this.loading = false
                    this.table_loading = false
                    this.skd_items = res.data.doc
                    this.total_num = res.data.total_num
                })
        }
    },
};
</script>
