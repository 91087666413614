<template>
  <v-list nav>
    <v-list-item>
      <v-list-item-icon>
        <v-icon color="primary">mdi-account-tie</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <!-- <v-img height="32" src="@/assets/logo.png"></v-img> -->
        <v-list-item-title class="text-h5 font-weight-black primary--text">优住管家<span
            class="text-subtitle-2 font-italic orange--text">v2.1</span></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group v-for="item in items" :key="item._id" v-model="item.active" :prepend-icon="item.icon" no-action>
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item v-for="child in item.children" :key="child._id" :to="child.link" @click="add_tab(child)">
        <!-- <v-list-item-icon>
          <v-icon v-text="child.icon"></v-icon>
        </v-list-item-icon> -->
        <v-list-item-content>
          <v-badge color="error" overlap :content="child.icon" offset-x="80" :value="child.icon ? true : false">
            <span>{{ child.name }}</span>
          </v-badge>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>

export default {
  data: () => ({
    user_doc: JSON.parse(sessionStorage.getItem('user_doc')),
    items: [],
    adminMenu: [
      {
        _id: "0",
        icon: "mdi-cog",
        name: "系统管理",
        link: "/xitongguanli",
        // 二级菜单
        children: [
          { _id: "1", icon: "", name: "系统设置", link: "/xitongguanli/xitongshezhi" },
          { _id: "2", icon: "", name: "模块管理", link: "/xitongguanli/mokuaiguanli" },
          { _id: "3", icon: "", name: "企业管理", link: "/xitongguanli/qiyeguanli" },
          { _id: "4", icon: "", name: "权限设置", link: "/xitongguanli/quanxianshezhi" },
          { _id: "5", icon: "", name: "合作申请", link: "/xitongguanli/join" }
        ],
      }
    ]
  }),
  methods: {
    add_tab(new_tab) {
      if (this.$store.state.tabs.indexOf(new_tab) == -1) {
        this.$store.state.tabs.push(new_tab)
        // 直接Push到store（如上）,或者采用执行变更模式（如下）
        // this.$store.commit("add",new_tab)
      }
    },
    // 获取菜单列表
    getlist() {
      this.$axios.get('navigation').then((res) => {
        if (this.user_doc.username == 'admin') {
          this.items = [...res.data, ...this.adminMenu]
        } else {
          this.items = res.data
        }
        // 自动打开收费窗口
        res.data.forEach(item => {
          if (item.name === '费用管理') {
            item.children.forEach(element => {
              if (element.name === '收取费用') {
                setTimeout(() => {
                  this.add_tab(element)
                  this.$router.push("/bill/get")
                }, 2000);
              }
            })
          }
        })
      })
    }
  },
  created() {
    this.getlist()
  }
};
</script>

<style></style>