<template>
	<v-row>
		<v-snackbar app top timeout="2000" :color="snackbar.color" v-model="snackbar.show">{{ snackbar.text }}
		</v-snackbar>
		<XiaoquLists @select_xiaoqu="select_xiaoqu"></XiaoquLists>
		<v-col cols="12" sm="9" md="9" style="height: 100%">
			<v-card height="100%" style="overflow: auto">
				<v-card-title>{{ xiaoqu ? xiaoqu.name : "" }} 台账清单
					<v-spacer></v-spacer>
					<v-btn color="primary" :disabled="!xiaoqu" @click="search" class="mx-5" :loading="loading"> 查询
					</v-btn>
					<download-excel :data="feiyong_items" :fields="excelFields"
						:name="(xiaoqu ? xiaoqu.name : '') + '台账'">
						<v-btn color="success" class="mr-5" :disabled="!feiyong_items.length" :loading="loading"> 导出
						</v-btn>
					</download-excel>
					<v-btn color="error" :disabled="feiyong_selected.length == 0" class="mr-5" @click="btn_del"> 删除
					</v-btn>
					<v-btn color="success" :disabled="!xiaoqu" @click="updateAll" :loading="loading"> 设置所有历欠台账到最新日期
					</v-btn>
				</v-card-title>

				<v-card-title class="flex-nowrap">
					<!-- 费项下拉框 -->
					<v-select multiple :disabled="xiaoqu ? false : true" :items="feixiang_items" label="费项"
						:item-text="(item) => item.mingcheng + item.danjia" item-value="_id" v-model="feixiang_selected"
						outlined dense hide-details class="mr-5">
						<template v-slot:selection="{ item, index }">
							<v-chip small v-if="index === 0">
								<span>{{ item.mingcheng }}</span>
							</v-chip>
							<span v-if="index === 1" class="grey--text text-caption"> (+{{ feixiang_selected.length - 1
								}} 其他) </span>
						</template>
					</v-select>

					<!-- 楼栋下拉框 -->
					<v-select :disabled="xiaoqu ? false : true" :items="loudong_items" label="楼栋" item-text="name"
						item-value="_id" v-model="loudong_selected" outlined dense hide-details class="mr-5"
						@change="change_loudong"></v-select>

					<!-- 单元下拉框 -->
					<v-select :disabled="loudong_selected != 0 ? false : true" :items="danyuan_items" label="单元"
						item-text="name" item-value="_id" v-model="danyuan_selected" outlined dense hide-details
						class="mr-5"></v-select>

					<!-- 是否缴费下拉框 -->
					<v-select :items="status_items" label="类型" v-model="status_selected" outlined dense hide-details
						class="mr-5"></v-select>
				</v-card-title>
				<v-card-title>时间范围计算
					<v-spacer></v-spacer>
					<!-- 开始时间 -->
					<V-menu v-model="startMenu" absolute :close-on-content-click="false">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="startDate" label="开始时间" prepend-icon="mdi-calendar" readonly
								v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
						</template>
						<v-date-picker v-model="startDate" @input="startMenu = false" locale="zh-CN"></v-date-picker>
					</V-menu>
					<!-- 结束时间 -->
					<V-menu v-model="endMenu" absolute :close-on-content-click="false">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="endDate" label="结束时间" prepend-icon="mdi-calendar" readonly
								v-bind="attrs" v-on="on" outlined dense hide-details></v-text-field>
						</template>
						<v-date-picker v-model="endDate" @input="endMenu = false" locale="zh-CN"></v-date-picker>
					</V-menu>

					<v-spacer></v-spacer>
					<v-btn color="primary" :disabled="!xiaoqu" @click="mathRsult" class="mx-5"> 计算
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-data-table v-model="feiyong_selected" :headers="headers" :items="feiyong_items" item-key="_id"
						:options.sync="options" :show-select="showselect" class="elevation-0" :loading="loading"
						:footer-props="{ 'items-per-page-options': [10, 50, 100, 1000, -1] }"
						:server-items-length="totalRecord" disable-sort height="60vh">
						<template v-slot:[`body.append`]="{ headers }">
							<tr>
								<td v-for="(header, i) in headers" :key="i">
									<div v-if="header.text == '楼栋'">当页汇总</div>
									<div v-if="header.text == '总计'">
										{{ Math.round(pageTotalZongji * 100) / 100 }}
									</div>
									<div v-if="header.text == '优惠'">
										{{ Math.round(pageTotalYouhui * 100) / 100 }}
									</div>
									<div v-if="header.text == '计算'">
										{{ Math.round(pageTotalMath * 100) / 100 }}
									</div>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>
<script>
import XiaoquLists from "@/components/xiaoqu-lists.vue";
import JsonExcel from "vue-json-excel";
import dayjs from "dayjs";
export default {
	components: {
		XiaoquLists,
		downloadExcel: JsonExcel,
	},
	data: () => ({
		xiaoqu: null,
		snackbar: {
			show: false,
			text: "",
			color: "success",
		},
		options: {},
		totalRecord: 0,
		loading: false,
		// 下拉框数组
		feixiang_items: [],
		loudong_items: [{ _id: 0, name: "全部" }],
		danyuan_items: [{ _id: 0, name: "全部" }],
		status_items: [
			{ value: 0, text: "未缴费" },
			{ value: 1, text: "已缴费" },
			{ value: 2, text: "已撤销" },
		],

		// 下拉框选择项
		feixiang_selected: [],
		loudong_selected: 0,
		danyuan_selected: 0,
		status_selected: 0,

		// 日期选择框
		startMenu: false,
		endMenu: false,
		startDate: dayjs().format('YYYY-MM-DD'),
		endDate: dayjs().format('YYYY-MM-DD'),

		// 费用台账选择项
		feiyong_selected: [],
		showselect: false,
		headers: [
			{ text: "楼栋", value: "loudong.name" },
			{ text: "单元", value: "danyuan.name" },
			{ text: "房屋", value: "fangwu.name" },
			{ text: "业主", value: "fangwu.yezhu" },
			{ text: "电话", value: "fangwu.phone" },
			{ text: "名称", value: "mingcheng" },
			{ text: "单价", value: "danjia" },
			{ text: "起数", value: "start" },
			{ text: "止数", value: "end" },
			{ text: "周期/倍数", value: "zhouqi_beishu" },
			{ text: "数量", value: "shuliang" },
			{ text: "天数", value: "diffDays" },
			{ text: "总计", value: "zongji" },
			{ text: "优惠", value: "youhui" },
			{ text: "备注", value: "beizhu" },
			{ text: "计算", value: "math" },
		],
		// 总计
		pageTotalZongji: 0,
		pageTotalYouhui: 0,
		pageTotalMath: 0,
		// 费用台账数组列表
		feiyong_items: [],
		excelFields: {
			小区: "xiaoqu.name",
			楼栋: "loudong.name",
			单元: "danyuan.name",
			房号: "fangwu.name",
			业主: "fangwu.yezhu",
			电话: "fangwu.phone",
			名称: "mingcheng",
			单价: "danjia",
			起数: "start",
			止数: "end",
			"周期/倍数": "zhouqi_beishu",
			数量: "shuliang",
			天数: "diffDays",
			总计: "zongji",
			优惠: "youhui",
			备注: "beizhu",
			计算: "math"
		},
	}),
	watch: {
		options: {
			handler() {
				if (this.xiaoqu) {
					this.search();
				}
			},
			deep: true,
		},
	},
	methods: {
		// 子组件事件 选择小区
		select_xiaoqu(xiaoqu) {
			// 清除
			this.feixiang_items = [{ _id: 0, mingcheng: "全部" }];
			this.feixiang_selected = [];
			this.loudong_items = [{ _id: 0, name: "全部" }];
			this.loudong_selected = 0;
			this.danyuan_items = [{ _id: 0, name: "全部" }];
			this.danyuan_selected = 0;

			this.feiyong_items = [];
			this.feiyong_selected = [];
			this.showselect = false;

			this.xiaoqu = xiaoqu;
			if (xiaoqu) {
				// 获取费项列表
				this.$axios.get("/feixiang/lists", { params: { xiaoqu: xiaoqu._id } }).then((res) => {
					this.feixiang_items = res.data;
				});

				// 获取楼栋列表
				this.$axios.get("/loudong/getlist", { params: { xiaoqu: xiaoqu._id } }).then((res) => {
					this.loudong_items = [{ _id: 0, name: "全部" }, ...res.data];
				});
			}
		},

		// 选择楼栋
		change_loudong() {
			this.danyuan_items = [{ _id: 0, name: "全部" }];
			this.danyuan_selected = 0;
			if (this.loudong_selected != 0) {
				this.$axios.get("/danyuan/getlist", { params: { loudong: this.loudong_selected } }).then((res) => {
					this.danyuan_items = [{ _id: 0, name: "全部" }, ...res.data];
				});
			}
		},

		// 查询
		search() {
			this.loading = true;
			this.pageTotalZongji = 0;
			this.pageTotalYouhui = 0;
			this.pageTotalMath = 0
			this.feiyong_items = [];
			this.feiyong_selected = [];
			if (this.status_selected == 0) {
				this.showselect = true;
			} else {
				this.showselect = false;
			}
			var post_data = {
				xiaoqu: this.xiaoqu,
				feixiang: this.feixiang_selected,
				loudong: this.loudong_selected,
				danyuan: this.danyuan_selected,
				status: this.status_selected,
				// 第几页
				page: this.options.page,
				// 每页条数
				itemsPerPage: this.options.itemsPerPage,
			};
			this.$axios.post("/taizhang", post_data).then(
				(res) => {
					console.log(res.data);
					this.loading = false;
					this.feiyong_items = res.data.doc;
					this.feiyong_items.forEach((item) => {
						this.pageTotalZongji += item.zongji;
						this.pageTotalYouhui += item.youhui;

						item.xiaoqu = this.xiaoqu;

						item.math = '待计算'
					});
					this.totalRecord = res.data.totalRecord;
				},
				(error) => {
					console.log("error:", error);
					this.loading = false;
					this.snackbar = {
						show: true,
						text: error.message,
						color: "error",
					};
				}
			);
		},

		// 删除选择的费用
		btn_del() {
			this.$axios.post("/feiyong/delete", this.feiyong_selected).then((res) => {
				console.log(res);
				this.feiyong_selected = [];
				this.search();
			});
		},

		//
		updateAll() {
			this.loading = true;
			this.$axios.post("/feiyong/updateAll", { xiaoqu: this.xiaoqu }).then((res) => {
				this.snackbar.text = res.data;
				this.snackbar.show = true;
				this.loading = false;
			});
		},
		// 
		mathRsult() {
			this.loading = true
			this.pageTotalMath = 0
			this.feiyong_items.forEach((item, index) => {
				var math = '正在计算'
				if (this.isValidDate(item.start)) {
					math = '0'
					if (this.endDate < this.startDate) {
						math = '日期错误'
					} else {
						// 都在范围外
						if (item.end < this.startDate || item.start > this.endDate) {
							math = 0
						} else {
							var days = 0
							// 数据包含开始日期和结束日期时
							if (item.start <= this.startDate && item.end >= this.endDate) {
								days = this.calculateDaysBetweenDates(this.startDate, this.endDate)
							} else if (item.start <= this.startDate && this.startDate <= item.end) {
								days = this.calculateDaysBetweenDates(this.startDate, item.end)
							} else if (this.startDate <= item.start && item.end >= this.endDate) {
								days = this.calculateDaysBetweenDates(item.start, this.endDate)
							} else if (this.startDate <= item.start && item.end <= this.endDate) {
								days = this.calculateDaysBetweenDates(item.start, item.end)
							}
							math = Math.round(days * (item.zongji / item.diffDays) * 100) / 100;
							this.pageTotalMath += math
						}
					}
				} else {
					math = ''
				}
				this.$set(this.feiyong_items, index, { ...item, math: math });
			});
			this.loading = false
			this.snackbar.text = '计算成功（每条最后一列）';
			this.snackbar.show = true;
		},
		// 判断是否为日期格式
		isValidDate(dateString) {
			// 正则表达式检查 "YYYY-MM-DD" 格式
			const regex = /^\d{4}-\d{2}-\d{2}$/;

			// 检查字符串是否符合格式
			if (!regex.test(dateString)) {
				return false;
			}

			// 尝试将字符串转换为日期对象
			const date = new Date(dateString);

			// 检查日期对象是否有效
			if (date instanceof Date && !isNaN(date)) {
				// 检查日期对象的值是否与原始字符串匹配
				return date.toISOString().slice(0, 10) === dateString;
			}

			return false;
		},
		// 计算两个字符串日期相差的天数
		calculateDaysBetweenDates(dateStr1, dateStr2) {
			// 将日期字符串转换为日期对象

			const date1 = new Date(dateStr1);
			const date2 = new Date(dateStr2);

			// 计算两个日期对象之间的差值（以毫秒为单位）
			const diff = date2 - date1;
			// 将差值转换为天数
			const days = Math.abs(diff / (1000 * 60 * 60 * 24));

			return days + 1;
		}
	},
};
</script>
